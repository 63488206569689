import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Define styled components outside of the React component
const PageContainer = styled.div`
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;  // Align sections horizontally by default
  justify-content: space-between; // Distribute space evenly
  align-items: stretch; // Stretch sections vertically
  width: 100%;
  height: 100vh; // Fill the screen vertically

  @media (max-width: 768px) {
    flex-direction: column; // Stack sections vertically on mobile
  }
`;

const Section = styled.div`
  flex: 1; // Each section takes up equal space
  padding: 100px 20px 20px 20px; // 100px top padding, 20px on other sides
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const ItemLink = styled.a`
  display: block; // Make links block-level for better control
  text-decoration: none;
  color: black;
  padding: 10px 0; // Padding for better touch area
  &:hover {
    background-color: #f8f8f8; // Visual feedback on hover
    cursor: pointer;
  }
`;

const SectionTitle = styled.h2`
  border-bottom: 1px solid black;
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: bold;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-family: 'Saira Condensed', sans-serif;
  padding-left: 250px;
  padding-top: 16px;
  padding-bottom: 32px;
`;

const ProfileImage = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const NameText = styled.div`
  position: absolute;
  top: 30px;
  left: 75px;
  font-family: 'Indie Flower', cursive;
  font-size: 1.5em;
  padding-left: 8px;
`;

const ProfileAndTitleContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;

  @media (max-width: 768px) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
  }
`;

const Description = styled.div`
  font-style: italic;
  color: grey;
  padding: 0 0 10px 0; // Padding below the description for spacing
`;

const SuggestionsPage = () => {
  return (
    <PageContainer>
      <ProfileAndTitleContainer>
        <Link to="/">
          <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
        </Link>
        <NameText>Andy Spezzatti</NameText>
        <Title>/ Suggestions.</Title>
      </ProfileAndTitleContainer>
      <Section>
        <SectionTitle>Books</SectionTitle>
        <ul>
          <li>
            <ItemLink href="https://example.com/book1">Incerto: Fooled by Randomness, The Black Swan, The Bed of Procrustes, Antifragile, Skin in the Game — Nassim Taleb</ItemLink>
            <Description>A collection of five books by Nassim Nicholas Taleb exploring randomness, uncertainty, probability, and decision-making.</Description>
          </li>
          <li>
            <ItemLink href="https://amzn.to/3JISgi8">Thinking Fast and Slow — Daniel Kahneman</ItemLink>
            <Description>A landmark book that dives into the dual-process theory of the mind and the biases associated with each type of thinking.</Description>
          </li>
          <li>
            <ItemLink href="https://amzn.to/4b0J5pe">Designing Your Life — Bill Burnett & Dave Evans</ItemLink>
            <Description>Practical advice and tools to help you build a life that works for you, leveraging design thinking principles.</Description>
          </li>
          <li>
            <ItemLink href="https://amzn.to/3Qwq9qf">Weapons of Math Destruction — Cathy O'Neil</ItemLink>
            <Description>Examines how big data and algorithms can increase inequality and threaten democracy.</Description>
          </li>
          <li>
            <ItemLink href="https://amzn.to/3Qwq9qf">The Almanach of Naval Ravikan — Cathy O'Neil</ItemLink>
            <Description>A compilation of Naval Ravikant's wisdom on wealth, happiness, and life, covering a broad range of topics from personal development to entrepreneurship.</Description>
          </li>
          <li>
            <ItemLink href="https://amzn.to/example">The Book of Why — Judea Pearl</ItemLink>
            <Description>An exploration of cause and effect in science and statistics, challenging traditional methods and introducing new tools for understanding causality.</Description>
          </li>
        </ul>
      </Section>
      <Section>
        <SectionTitle>Podcasts</SectionTitle>
        <ul>
          <li>
            <ItemLink href="https://lexfridman.com/podcast/">Lex Friedman</ItemLink>
            <Description>Deep conversations exploring the minds of some of the world’s most remarkable people.</Description>
          </li>
          <li>
            <ItemLink href="https://www.gdiy.fr/">Generation Do It Yourself</ItemLink>
            <Description>[French] Dive into the details of personal & professional stories of innovators, entrepreneurs and athletes, offering practical advice and inspiration.</Description>
          </li>
          <li>
            <ItemLink href="https://www.hubermanlab.com/podcast">Huberman Lab</ItemLink>
            <Description>In-depth insights into neuroscience and biology, providing practical tools and science-based advice to improve health, brain function, and overall personal productivity</Description>
          </li>
          <li>
            <ItemLink href="https://www.dwarkeshpatel.com/podcast">Dwarkesh</ItemLink>
            <Description>Explores deep intellectual & philosophical topics through conversations with thinkers, founders, and scholars, offering insights into innovative ideas and the minds shaping the future.</Description>
          </li>
          <li>
            <ItemLink href="https://open.spotify.com/show/5t7l5Vv8goSi5tm7YP8C8m">A Product Market Fit Show</ItemLink>
            <Description>A discussion forum for entrepreneurs and innovators to dissect what makes a product fit into the market.</Description>
          </li>
        </ul>
      </Section>
      <Section>
        <SectionTitle>Quotes</SectionTitle>
        <ul>
          <li><ItemLink href="https://www.google.com/search?q=Nassim+Nicholas+Taleb">“This is the central illusion in life: that randomness is a risk, that it is a bad thing” — Nassim Nicholas Taleb</ItemLink></li>
          <li><ItemLink href="https://www.google.com/search?q=Paul+Graham">"Do something people want." Paul Graham</ItemLink></li>
          <li><ItemLink href="https://www.google.com/search?q=John+Maynard+Keynes">“When the facts change, I change my mind - what do you do, sir?” John Maynard Keynes</ItemLink></li>
          <li><ItemLink href="https://www.google.com/search?q=Jony+Ive">“Simplicity is not the absence of clutter, but the presence of clarity.” Jony Ive</ItemLink></li>
          <li><ItemLink href="https://www.google.com/search?q=Jony+Ive">“The best design makes complex things feel simple.” Jony Ive</ItemLink></li>
          <li><ItemLink href="https://www.google.com/search?q=Jony+Ive">“Simplicity is the ultimate sophistication.” Jony Ive</ItemLink></li>
        </ul>
      </Section>
    </PageContainer>
  );
};

export default SuggestionsPage;