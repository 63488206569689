import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProjectsContainer = styled('div')({
  paddingTop: '125px',
});

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '@media (max-width: 768px)': {
    display: 'none',  // Hide the row layout on mobile
  },
});

const ProjectBox = styled('div')({
  width: '275px',
  height: '300px', // Set a fixed height for the cards
  borderRadius: '20px',
  border: '1px solid #ddd',
  padding: '20px',
  margin: '10px',
  textAlign: 'center',
  color: '#333',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
  },
  '@media (max-width: 768px)': {
    width: 'calc(100% - 40px)',  // Full width minus margin for padding and gap
    margin: '0 auto',  // Center the card with margin on top and bottom
  },
});

const ProjectTitle = styled('h3')({
  margin: '10px 0',
});

const ProjectDescription = styled('p')({
  fontSize: '0.9rem',
});

const GitHubLink = styled(Link)({
  display: 'block',
  marginTop: '10px',
});

const Title = styled('h1')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin: '0 0 20px 0',
  padding: 0,
  lineHeight: '1',
  fontFamily: '"Saira Condensed", sans-serif',
  paddingLeft: '250px',
  paddingTop: '16px',
  paddingBottom: '32px',
  '@media (max-width: 768px)': {
    fontSize: '2em',
    paddingLeft: '0px',
    textAlign: 'center',
  },
});

const ProfileImage = styled('img')({
  position: 'absolute',
  top: '20px',
  left: '20px',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    margin: '0 auto',
    display: 'block',
  },
});

const NameText = styled('div')({
  position: 'absolute',
  top: '30px',
  left: '75px',
  fontFamily: '"Indie Flower", cursive',
  fontSize: '1.5em',
  paddingLeft: '8px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    textAlign: 'center',
    marginTop: '10px',
  },
});

const ProfileAndTitleContainer = styled('div')({
  position: 'absolute',
  top: '0px',
  left: '0px',
  zIndex: 10,
  '@media (max-width: 768px)': {
    position: 'relative',
    display: 'block',
    textAlign: 'center',
    marginBottom: '20px',
  },
});

const IconLink = styled(Link)({
  display: 'inline-block',
  marginRight: '10px',
});

const SliderContainer = styled('div')({
  display: 'none',
  '@media (max-width: 768px)': {
    display: 'block',
    padding: '0 20px', // Add padding to ensure there is a gap between the cards
  },
});

const projectsData = [
  {
    title: 'The SDG Data Catalog.',
    description: 'An expansive, AI-powered global database, the SDG Data Catalog compiles datasets, metadata, and research networks from open access academic publications. It utilizes AI and Natural Language Processing to systematically organize vast knowledge. The project has been recognized in two conference papers.',
    githubUrl: 'https://github.com/sdg-ai/sdg_data_catalog',
    websiteUrl: 'https://ai4good.org/what-we-do/sdg-data-catalog/',
  },
  {
    title: 'DNA Finder.',
    description: 'By drawing parallels between genomic sequences and linguistic structures, we demonstrate the feasibility of adapting deep learning models originally designed for language processing to the analysis of DNA sequences. We create a language of tokens that are composed of nucleotides.',
    githubUrl: 'https://github.com/AndySECP/MicroBioLLM',
  },
  {
    title: 'The Diversity Equity & Inclusion Tracker.',
    description: 'The DE&I scorecard track and publish monthly updates for 1.3M+ organisations in the United States, showing progress towards even opportunities across the labour market, as well as within particular segments, occupations, and more.',
    githubUrl: 'https://github.com/sdg-ai/diversity',
    websiteUrl: 'https://ai4good.org/what-we-do/workforce-diversity-ai/',
  },
  {
    title: 'SciKid-Learn.',
    description: 'An interactive tool that optimize students learning based on their coginitives preferences and learning style.',
    githubUrl: 'https://github.com/AndySECP/SciKid-Learn',
    paperUrl: 'https://www.kdd.org/kdd2019/docs/SciKid_Learn.pdf',
  },
  {
    title: 'Maia: Neural Network for Music Generation.',
    description: 'Deep Neural Network that can generate original piano solo compositions. Trained on a corpus of classical music from Mozart to Chopin, the model discovered patterns of harmony, rhythm, and style to predict the next token in thousand of midi files.',
    githubUrl: 'https://github.com/AndySECP/Neural-Network-Music-Generation',
    mediumUrl: 'https://medium.com/towards-data-science/neural-networks-for-music-generation-97c983b50204',
  },
  {
    title: 'Z-inspection: A process to assess trustworthy AI.',
    description: "The ethical and societal implications of artificial intelligence systems raise concerns. We defined a novel process based on applied ethics, namely Z-Inspection®, to assess if an AI system is trustworthy. We use the definition of trustworthy AI given by the high-level European Commission’s expert group on AI. The Z-Inspection® process will play a key role in the context of the new EU Artificial Intelligence (AI) regulation.",
    websiteUrl: 'https://z-inspection.org/',
  },
];

const ProjectSection = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '20px', // Padding to ensure there is a gap between the cards
  };

  return (
    <ProjectsContainer>
      <ProfileAndTitleContainer>
        <Link to="/">
          <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
        </Link>
        <NameText>Andy Spezzatti</NameText>
        <Title>/ Projects.</Title>
      </ProfileAndTitleContainer>
      <Row>
        {projectsData.slice(0, 3).map((project, index) => (
          <ProjectBox key={index}>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
            <div>
              {project.githubUrl && (
                <IconLink to={project.githubUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/github.png" alt="GitHub" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
              {project.mediumUrl && (
                <IconLink to={project.mediumUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/medium.png" alt="Medium" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
              {project.websiteUrl && (
                <IconLink to={project.websiteUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/website.png" alt="Website" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
              {project.paperUrl && (
                <IconLink to={project.paperUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/paper.png" alt="Paper" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
            </div>
          </ProjectBox>
        ))}
      </Row>
      <Row>
        {projectsData.slice(3, 6).map((project, index) => (
          <ProjectBox key={index}>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
            <div>
              {project.githubUrl && (
                <IconLink to={project.githubUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/github.png" alt="GitHub" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
              {project.mediumUrl && (
                <IconLink to={project.mediumUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/medium.png" alt="Medium" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
              {project.websiteUrl && (
                <IconLink to={project.websiteUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/website.png" alt="Website" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
              {project.paperUrl && (
                <IconLink to={project.paperUrl} target="_blank" rel="noopener noreferrer">
                  <img src="/icons/paper.png" alt="Paper" style={{ width: '24px', height: '24px' }} />
                </IconLink>
              )}
            </div>
          </ProjectBox>
        ))}
      </Row>
      <SliderContainer>
        <Slider {...sliderSettings}>
          {projectsData.map((project, index) => (
            <div key={index} style={{ padding: '0 10px' }}> {/* Add padding to each slide */}
              <ProjectBox>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>{project.description}</ProjectDescription>
                <div>
                  {project.githubUrl && (
                    <IconLink to={project.githubUrl} target="_blank" rel="noopener noreferrer">
                      <img src="/icons/github.png" alt="GitHub" style={{ width: '24px', height: '24px' }} />
                    </IconLink>
                  )}
                  {project.mediumUrl && (
                    <IconLink to={project.mediumUrl} target="_blank" rel="noopener noreferrer">
                      <img src="/icons/medium.png" alt="Medium" style={{ width: '24px', height: '24px' }} />
                    </IconLink>
                  )}
                  {project.websiteUrl && (
                    <IconLink to={project.websiteUrl} target="_blank" rel="noopener noreferrer">
                      <img src="/icons/website.png" alt="Website" style={{ width: '24px', height: '24px' }} />
                    </IconLink>
                  )}
                  {project.paperUrl && (
                    <IconLink to={project.paperUrl} target="_blank" rel="noopener noreferrer">
                      <img src="/icons/paper.png" alt="Paper" style={{ width: '24px', height: '24px' }} />
                    </IconLink>
                  )}
                </div>
              </ProjectBox>
            </div>
          ))}
        </Slider>
      </SliderContainer>
    </ProjectsContainer>
  );
};

export default ProjectSection;
