import React from 'react';
import { styled } from '@mui/system';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ShareIcon from '@mui/icons-material/Share';
import DescriptionIcon from '@mui/icons-material/Description';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import DialpadIcon from '@mui/icons-material/Dialpad';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { Link } from 'react-router-dom';

// Create styled components for different parts of the layout
const Layout = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
});

const Sidebar = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Center content vertically
  alignItems: 'center', // Center content horizontally
  width: '40%',
  backgroundColor: 'rgb(255,255,255)',
  color: '#333',
  padding: '20px',
  boxSizing: 'border-box',
  position: 'relative',
  minHeight: '100vh',
  '@media (max-width: 768px)': {
    width: '100%',
    paddingTop: '20px',
    padding: '20px 10px',
    minHeight: 'auto',
    flex: 1, // Allow Sidebar to take available space
  },
});

const ProfileImage = styled('img')({
  position: 'absolute',
  top: '20px',
  left: '20px',
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    width: '60px',
    height: '60px',
  },
});

const Main = styled('div')({
  width: '60%',
  backgroundColor: '#1A1A1A',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media (max-width: 768px)': {
    width: '100%',
    padding: '20px 10px',
  },
});

const Title = styled('h1')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin: '0 0 20px 0',
  padding: 0,
  lineHeight: '1',
  fontFamily: '"Saira Condensed", sans-serif',
  '@media (max-width: 768px)': {
    fontSize: '2em',
    textAlign: 'center',
  },
});

const NameText = styled('div')({
  position: 'absolute',
  top: '50px',
  left: '110px',
  fontFamily: '"Indie Flower", cursive',
  fontSize: '1.5em',
  paddingLeft: '8px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    textAlign: 'center',
    fontSize: '1.2em',
    marginTop: '10px',
  },
});

const Description = styled('p')({
  fontSize: '1em',
  lineHeight: '1.5',
  fontFamily: '"Poppins", sans-serif',
  marginBottom: '10px', // Reduce whitespace specifically for all views
  '@media (max-width: 768px)': {
    textAlign: 'center',
    fontSize: '0.9em',
    marginBottom: '5px', // Further reduce whitespace specifically for mobile view
  },
});

const TabsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const TabDiv = styled('div')<{ color: string }>(({ color }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginLeft: '60px',
  height: '70px',
  backgroundColor: color,
  marginBottom: '24px',
  borderRadius: '50px',
  boxSizing: 'border-box',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '@media (max-width: 768px)': {
    marginLeft: '0',
    marginBottom: '12px',
    height: '60px',
    borderRadius: '30px',
  },
}));

const getFontColor = (index: number) => (index % 2 === 0 ? 'white' : 'black');

const Letter = styled('span')<{ index: number }>(({ index }) => ({
  color: getFontColor(index),
  fontFamily: '"Poppins", sans-serif',
  fontWeight: 'bold',
  fontSize: '1.3rem',
  zIndex: 2,
  '@media (max-width: 768px)': {
    fontSize: '1rem',
  },
}));

const colorMap: { [key: string]: string } = {
  blue: '#007AFF',
  black: '#1C1C1E',
  yellow: '#F2F2F7',
  peach: '#A2A2A6',
  orange: '#3A3A3C',
  red: '#58585A',
  purple: '#8E8E93',
  darkBlue: '#0A84FF',
  lightBlue: '#5AC8FA',
  darkGray: '#1C1C1E',
  gray: '#8E8E93',
  lightGray: '#E5E5EA',
  white: '#FFFFFF',
  airsupblue: '#72A0C1',
  columbiablue: '#B9D9EB',
  marianblue: '#E1EBEE',
  marverickblue: '#00416A',
  royalairforceblue: '#5D8AA8',
  mariandarker: 'rgb(200, 210, 213)',
  crystaltealblue: '#00637C',
};

const IconWrapper = styled('div')<{ index: number }>(({ index }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '16px',
  marginLeft: '30px',
  color: getFontColor(index),
  '@media (max-width: 768px)': {
    marginLeft: '10px',
  },
}));

const Tab: React.FC<{ letter: string; color: string; index: number; Icon: React.ElementType, path?: string }> = ({
  letter,
  color,
  index,
  Icon,
  path,
}) => {
  return (
    <Link to={path || '#'} style={{ textDecoration: 'none' }}>
      <TabDiv color={colorMap[color]}>
        <IconWrapper index={index}>
          <Icon />
        </IconWrapper>
        <Letter index={index}>{letter}</Letter>
      </TabDiv>
    </Link>
  );
};

const MusicTabs: React.FC = () => {
  const tabs = [
    { letter: 'About', icon: ScatterPlotIcon, color: 'crystaltealblue', path: '/about' },
    { letter: 'Roles', icon: CollectionsBookmarkIcon, color: 'marianblue', path: '/role' },
    { letter: 'Socials', icon: ShareIcon, color: 'crystaltealblue', path: '/social' },
    { letter: 'Publication & Conferences', icon: DescriptionIcon, color: 'marianblue', path: '/paper' },
    { letter: 'Blog & Mentions', icon: BookmarksIcon, color: 'crystaltealblue', path: '/mention' },
    { letter: 'Suggestions', icon: LightbulbIcon, color: 'marianblue', path: '/suggest' },
    { letter: 'Contact', icon: DialpadIcon, color: 'crystaltealblue', path: '/contact' },
    { letter: 'Projects', icon: DialpadIcon, color: 'marianblue', path: '/project' },
  ];

  return (
    <Layout>
      <Sidebar>
        <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
        <NameText sx={{ paddingBottom: "12px" }}>Andy Spezzatti</NameText>
        <Title>Entrepreneur.</Title>
        <Title>Researcher.</Title>
        <Title>AI Lead.</Title>
        <Description>
          Entrepreneur and innovator with a proven track record of leading AI initiatives and R&D teams. Expertise in translating complex analytics into actionable business strategies, resulting in multimillion-dollar cost savings and revenue growth across diverse industries. Passionate about leveraging deep-tech innovation for social and environmental impact, having directed AI researchers in developing solutions aligned with sustainable development goals.
        </Description>
      </Sidebar>
      <Main>
        <TabsContainer>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.letter}
              letter={tab.letter}
              color={tab.color}
              index={index}
              Icon={tab.icon}
              path={tab.path}
            />
          ))}
        </TabsContainer>
      </Main>
    </Layout>
  );
};

export default MusicTabs;
