import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  paddingTop: '100px',
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '750px',
  backgroundColor: '#ffffff',
  padding: '20px',
  borderRadius: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  marginTop: '20px',
  '@media (max-width: 768px)': {
    width: '90%',
    padding: '10px',
  },
});

const Input = styled('input')({
  padding: '10px',
  borderRadius: '8px',
  border: '1px solid #ccc',
  marginBottom: '15px',
  fontFamily: '"Poppins", sans-serif',
  flex: '1',
  '@media (max-width: 768px)': {
    marginBottom: '10px',
  },
});

const TextArea = styled('textarea')({
  height: '150px',
  padding: '10px',
  marginBottom: '15px',
  borderRadius: '8px',
  border: '1px solid #ccc',
  fontFamily: '"Poppins", sans-serif',
  '@media (max-width: 768px)': {
    height: '100px',
    marginBottom: '10px',
  },
});

const Button = styled('button')({
  background: '#000',
  color: 'white',
  border: 'none',
  borderRadius: '8px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontFamily: '"Poppins", sans-serif',
  '&:hover': {
    background: '#333',
  },
});

const IntroMessage = styled('p')({
  color: '#333',
  width: '750px',
  textAlign: 'center',
  fontFamily: '"Poppins", sans-serif',
  lineHeight: '1.6',
  margin: '0 auto 20px auto',
  fontSize: '20px',
  '@media (max-width: 768px)': {
    width: '90%',
    fontSize: '18px',
  },
});

const Title = styled('h1')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin: '0 0 20px 0',
  padding: 0,
  lineHeight: '1',
  fontFamily: '"Saira Condensed", sans-serif',
  paddingLeft: '250px',
  paddingTop: '16px',
  paddingBottom: '32px',
  '@media (max-width: 768px)': {
    fontSize: '2em',
    paddingLeft: '0px',
    textAlign: 'center',
  },
});

const ProfileImage = styled('img')({
  position: 'absolute',
  top: '20px',
  left: '20px',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    margin: '0 auto',
    display: 'block',
  },
});

const NameText = styled('div')({
  position: 'absolute',
  top: '30px',
  left: '75px',
  fontFamily: '"Indie Flower", cursive',
  fontSize: '1.5em',
  paddingLeft: '8px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    textAlign: 'center',
    marginTop: '10px',
  },
});

const InputRow = styled('div')({
  display: 'flex',
  gap: '15px', // Add space between the two inputs
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
});

const Header = styled('div')({
  width: '100%',
  textAlign: 'center',
  padding: '10px 0', // Less padding vertically
  backgroundColor: '#fff',
  borderBottom: '1px solid #ddd',
});

const ProfileAndTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center align the items horizontally
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    textAlign: 'center',
  },
});

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const emailData = {
      from_name: formData.name,
      from_email: formData.email,
      to_name: 'Andy Spezzatti',
      to_email: 'andyspezzatti@gmail.com',
      subject: formData.subject,
      message: formData.message,
    };

    emailjs.send(
      'service_email', // Replace with your service ID
      'template_i1zai65', // Replace with your template ID
      emailData,
      'JusBCH2ajHRgMFS7d' // Replace with your user ID
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Message sent!');
    }).catch((err) => {
      console.error('FAILED...', err);
      alert('Failed to send message. Please try again later.');
    });
  };

  return (
    <div>
      <Header>
        <ProfileAndTitleContainer>
          <Link to="/">
            <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
          </Link>
          <NameText>Andy Spezzatti</NameText>
          <Title>/ Contact.</Title>
        </ProfileAndTitleContainer>
      </Header>
      <FormContainer>
        <IntroMessage>
          I am happy to hear from you, always up for a good chat! Got ideas, looking to collaborate, or just want to share what's on your mind?
        </IntroMessage>
        <Form onSubmit={handleSubmit}>
          <InputRow>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </InputRow>
          <Input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <TextArea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <Button type="submit">Send</Button>
        </Form>
      </FormContainer>
    </div>
  );
};

export default ContactPage;
