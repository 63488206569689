import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

// Styled components
const AboutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '"Poppins", sans-serif',
  minHeight: '100vh',
  width: '100%',
  '@media (min-width: 769px)': {
    flexDirection: 'column',
  },
});

const Header = styled('div')({
  width: '100%',
  textAlign: 'center',
  padding: '10px 0', // Less padding vertically
  backgroundColor: '#fff',
  borderBottom: '1px solid #ddd',
});

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'stretch', // Added
  '@media (min-width: 769px)': {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'stretch', // Added
  },
});

const Section = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  '@media (min-width: 769px)': {
    padding: '40px 20px',
  },
});

const LeftSection = styled(Section)({
  backgroundColor: '#fff',
});

const RightSection = styled(Section)({
  backgroundColor: '#000',
  color: '#fff',
});

const SectionTitle = styled('h2')({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '20px',
});

const SectionText = styled('p')({
  lineHeight: '1.6',
});

const Title = styled('h1')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin: '0 0 10px 0',
  padding: 0,
  lineHeight: '1',
  fontFamily: '"Saira Condensed", sans-serif',
  paddingLeft: '10px', // Adjust padding on the left of the title
  paddingTop: '0px', // Remove top padding
  paddingBottom: '0px', // Remove bottom padding
  '@media (max-width: 768px)': {
    fontSize: '2em',
    paddingLeft: '0px',
    textAlign: 'center',
  },
});

const ProfileImage = styled('img')({
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  marginRight: '10px', // More margin to the right of the icon
  '@media (max-width: 768px)': {
    margin: '0 auto 10px',
    display: 'block',
  },
});

const NameText = styled('div')({
  fontFamily: '"Indie Flower", cursive',
  fontSize: '1.5em',
  paddingLeft: '8px',
  marginRight: '10px', // Less margin to bring the title closer to the name
  '@media (max-width: 768px)': {
    textAlign: 'center',
    marginTop: '10px',
  },
});

const ProfileAndTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center align the items horizontally
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    textAlign: 'center',
  },
});

const ImageSection = styled(Section)({
  padding: 0,
  justifyContent: 'stretch',
  flex: 1, // Added
});

const ImageContainer = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: 0,
});

const Image = styled('img')({
  width: '100%',
  height: '100%', // Added to fill the height
  objectFit: 'cover',
  objectPosition: 'center',
  margin: 0, // Added to remove any default margins
});

const AboutSection = () => {
  return (
    <AboutContainer>
      <Header>
        <ProfileAndTitleContainer>
          <Link to="/">
            <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
          </Link>
          <NameText>Andy Spezzatti</NameText>
          <Title>/ About.</Title>
        </ProfileAndTitleContainer>
      </Header>
      <ContentContainer>
        <RightSection>
          <SectionTitle sx={{ marginTop: "0px" }}>My work.</SectionTitle>
          <SectionText>
            I'm a technology leader passionate about harnessing AI and data science to drive innovation and create meaningful impact in various sectors.
            As CTO at Taltrics, I lead the development of our AI agents to support leaders in optimizing resource allocation and accelerating pre-sales processes. My commitment to using technology for good extends to various initiatives. <br /> <br />

            I lead several projects for the AI4Good foundation, using AI to address global development challenges. Notable among these is the SDG Data Catalog, a Microsoft-funded initiative, where we created the first global database of data sets, metadata, and research networks related to the SDGs. I've also spearheaded our Diversity, Inclusion, and Equity initiative and an AI-driven education tool project.
          </SectionText>
        </RightSection>
        <RightSection>
          <SectionTitle></SectionTitle>
          <SectionText>
            From 2019 to 2023, I served as Sr. Portfolio Data Scientist Lead at Roche & Genentech, where I supported clinical operations for their extensive portfolio. During this time, I led initiatives to enhance clinical trial staffing and refine geographic strategy. <br /> <br />

            Throughout my career, I've focused on developing cutting-edge technologies with real-world applications. I thrive on challenges and am continually inspired by collaborating with brilliant minds who share my vision of technology as a force for good and business value.
          </SectionText>
        </RightSection>
      </ContentContainer>
      <ContentContainer>
        <ImageSection>
          <ImageContainer>
            <Image src="/pizza.png" alt="Pizza" />
          </ImageContainer>
        </ImageSection>
        <LeftSection>
          <SectionTitle>My background is like a pizza.</SectionTitle>
          <SectionText>
            The base is Mathematics and Computer Science, the sauce is Artificial Intelligence and Product Design and the toppings is Business and Leadership.
            I graduated from a Master of Engineering from UC Berkeley, focusing on AI, optimization and business. 40% of my master was with the Haas Business School, focusing on leadership, entrepreneuship and finance.
            During that time, I also did research on the application of large language models to music composition, and joined the AI4Good foundation as a Project Leader, where I launched new initiatives focused on education and data accessibility for SDG research.
            Prior to that, I graduated from a Master of Science in Applied Mathematics and Computer Science from Ecole Centrale Paris.
          </SectionText>
        </LeftSection>
      </ContentContainer>
      <ContentContainer>
        <RightSection>
          <SectionTitle>Hobbies and Interests.</SectionTitle>
          <SectionText>
            Sports have always been a central part of my life.
            In high school, I was a student athlete specializing in mid-distance track events, such as the 800m and 1500m.
            This led to multiple podium finishes in the junior category at the French National Track & Field Championships.
            Soccer is another sport I have practiced a lot, playing for many years, including during my time at UC Berkeley, where our team won the IM League title twice.
            Most of my free time is spent outdoors, I love hiking in national parks, skiing in the french alps, biking in the marin county, surfing, and exploring new places.
            Being in nature is what revitalizes me the most.
          </SectionText>
        </RightSection>
        <RightSection>
          <Image src="/oxnard.png" alt="Oxnard" />
        </RightSection>
      </ContentContainer>
      <ContentContainer>
        <LeftSection>
          <SectionTitle>Artificial Intelligence and Data.</SectionTitle>
          <SectionText>
            Throughout my journey, I've been authoring numerous impactful papers on AI application or AI safety topics.
            I authored 4 papers on the safety and explainability of different AI systems, more particularly in the clinical settings.
            For these I collaborated closely with clinical scientists, legal experts, AI experts, doctors of medicine and professors.
            I also authored 3 papers on AI applications, as part of my work at AI4Good. These works were also presented at 3 AI conferences.
            I published one chapter in the book "The Ethics of Artificial Intelligence for the Sustainable Development Goals." published in Springer Nature
            as part of Francesca Mazzia and Luciano Floridi work at the Oxford Said Business School.
          </SectionText>
        </LeftSection>
        <LeftSection>
          <Image src="/alps.png" alt="Alps" />
        </LeftSection>
      </ContentContainer>
      <ContentContainer>
        <RightSection>
          <SectionTitle>Business Strategy & Optimization.</SectionTitle>
          <SectionText>
            I've driven operational and AI strategies across various organizations. At Genentech, as Senior Portfolio Data Scientist Lead, I spearheaded initiatives to enhance clinical trial efficiency:

            Developed a probabilistic model for optimal country selection using Bayesian Optimization, epidemiology data, and operational insights.
            Supported resource allocation for late-stage trials, forecasting FTE needs and required skills throughout the trial lifecycle.

            At Taltrics, I assisted CROs, manufacturers, and professional services firms with resourcing and operational strategies. This included leading business software integration and managing associated process changes.
          </SectionText>
        </RightSection>
        <RightSection>
          <Image src="/compass.png" alt="Compass" />
        </RightSection>
      </ContentContainer>
    </AboutContainer>
  );
};

export default AboutSection;
