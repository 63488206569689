import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const Container = styled('div')({
  padding: '20px',
  '@media (max-width: 768px)': {
    padding: '10px',
  },
});

const MentionsList = styled('ul')({
  listStyleType: 'none',
  position: 'relative',
  paddingLeft: '0',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '150px',
    top: 0,
    bottom: 0,
    width: '2px',
    backgroundColor: 'black',
    '@media (max-width: 768px)': {
      left: '10%',
    },
  },
});

const ListItem = styled('li')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  position: 'relative',
  paddingLeft: '0',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '150px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'black',
    '@media (max-width: 768px)': {
      left: '10%',
    },
  },
});

const DateText = styled('span')({
  fontFamily: '"Indie Flower", cursive',
  color: 'black',
  textAlign: 'right',
  position: 'absolute',
  left: '0px',
  top: '50%',
  transform: 'translateY(-50%)',
  whiteSpace: 'nowrap',
  fontSize: '1em',
  width: '130px',
  '@media (max-width: 768px)': {
    width: '20%',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'left',
  },
});

const MentionLink = styled('a')({
  display: 'block',
  color: 'black',
  textDecoration: 'none',
  fontFamily: '"Poppins", sans-serif',
  padding: '10px',
  marginLeft: '170px',
  flexGrow: 1,
  '@media (max-width: 768px)': {
    marginLeft: '20%',
  },
  '&:hover': {
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  },
});

const BoldText = styled('span')({
  fontWeight: 'bold',
  marginLeft: '20px',
});

const ItalicText = styled('span')({
  fontStyle: 'italic',
});

const YouTubeLogo = styled('img')({
  width: '24px',
  height: 'auto',
  marginLeft: '18px',
});

const Title = styled('h1')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin: '0 0 20px 0',
  padding: 0,
  lineHeight: '1',
  fontFamily: '"Saira Condensed", sans-serif',
  paddingLeft: '250px',
  paddingTop: '16px',
  paddingBottom: '32px',
  '@media (max-width: 768px)': {
    fontSize: '2em',
    paddingLeft: '0',
    textAlign: 'center',
  },
});

const ProfileImage = styled('img')({
  position: 'absolute',
  top: '20px',
  left: '20px',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    margin: '0 auto',
    display: 'block',
  },
});

const NameText = styled('div')({
  position: 'absolute',
  top: '30px',
  left: '75px',
  fontFamily: '"Indie Flower", cursive',
  fontSize: '1.5em',
  paddingLeft: '8px',
  '@media (max-width: 768px)': {
    position: 'relative',
    top: '0',
    left: '0',
    textAlign: 'center',
    marginTop: '10px',
  },
});

const Icon = styled('img')({
  width: '24px',
  height: '24px',
  marginLeft: '10px',
});

const MentionsPage = () => {
  return (
    <Container>
      <Link to="/">
        <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
      </Link>
      <NameText>Andy Spezzatti</NameText>
      <Title>/ Papers and Conferences.</Title>
      <MentionsList>
        <ListItem>
          <DateText>
            May <br /> 2023
          </DateText>
          <MentionLink href="https://link.springer.com/chapter/10.1007/978-3-031-21147-8_24" target="_blank">
            <Icon src="/icons/springer2.jpeg" alt="Springer Icon" />
            <BoldText>The Ethics of Artificial Intelligence for the Sustainable Development Goals </BoldText> - <ItalicText> Sailing the Data Sea to Advance Research on the Sustainable Development Goals</ItalicText>
          </MentionLink>
        </ListItem>
        <ListItem>
          <DateText>
            Feb <br /> 2022
          </DateText>
          <MentionLink href="https://journals.plos.org/digitalhealth/article?id=10.1371/journal.pdig.0000016" target="_blank">
            <Icon src="/icons/plos.png" alt="PLOS Icon" />
            <BoldText>PLOS Digital Health </BoldText> - <ItalicText> To explain or not to explain?—Artificial intelligence explainability in clinical decision support systems</ItalicText>
          </MentionLink>
        </ListItem>
        <ListItem>
          <DateText>
            Jul <br /> 2021
          </DateText>
          <MentionLink href="https://www.frontiersin.org/articles/10.3389/fhumd.2021.688152/full" target="_blank">
            <Icon src="/icons/frontiers.png" alt="Frontiers Icon" />
            <BoldText>Frontier in Human Dynamics </BoldText> - <ItalicText> Co-Design of a Trustworthy AI System in Healthcare: Deep Learning Based Skin Lesion Classifier</ItalicText>
          </MentionLink>
        </ListItem>
        <ListItem>
          <DateText>
            Jul <br /> 2021
          </DateText>
          <MentionLink href="https://www.frontiersin.org/articles/10.3389/fhumd.2021.673104/full" target="_blank">
            <Icon src="/icons/frontiers.png" alt="Frontiers Icon" />
            <BoldText>Frontier in Human Dynamics</BoldText> - <ItalicText> On Assessing Trustworthy AI in Healthcare. Machine Learning as a Supportive Tool to Recognize Cardiac Arrest in Emergency Calls</ItalicText>
          </MentionLink>
        </ListItem>
        <ListItem>
          <DateText>
            Jul <br /> 2022
          </DateText>
          <MentionLink href="https://ieeexplore.ieee.org/abstract/document/9845195" target="_blank">
            <Icon src="/icons/ieee3.png" alt="IEEE Icon" />
            <BoldText>IEEE Transactions on Technology and Society</BoldText> — <ItalicText>Assessing Trustworthy AI in Times of COVID-19: Deep Learning for Predicting a Multiregional Score Conveying the Degree of Lung Compromise in COVID-19 Patients</ItalicText>
          </MentionLink>
        </ListItem>
        <ListItem>
          <DateText>
            Jun <br /> 2022
          </DateText>
          <MentionLink href="https://dl.acm.org/doi/abs/10.1145/3530190.3534829" target="_blank">
            <Icon src="/icons/acm.png" alt="ACM Icon" />
            <BoldText>ACM COMPASS '22</BoldText> — <ItalicText>Note: Leveraging Artificial Intelligence to build a Data Catalog and support research on the Sustainable Development Goals</ItalicText>
          </MentionLink>
        </ListItem>
        <ListItem>
          <DateText>
            Dec <br /> 2020
          </DateText>
          <MentionLink href="https://link.springer.com/chapter/10.1007/978-981-15-8354-4_79" target="_blank">
            <Icon src="/icons/springer2.jpeg" alt="Springer Icon" />
            <BoldText>ICT Analysis and Application </BoldText> - <ItalicText> Hidden in Plain Sight: Building a Global Sustainable Development Data Catalogue</ItalicText>
          </MentionLink>
        </ListItem>
        <ListItem>
          <DateText>
            Oct <br /> 2023
          </DateText>
          <MentionLink href="https://www.youtube.com/watch?v=gAx4hdOuAs4&t=0s" target="_blank">
            <Icon src="/icons/kdd.png" alt="KDD Icon" />
            <BoldText>SIGKDD 2019, Social Impact Workshop</BoldText> — <ItalicText>Scikid-Learn: An AI tool for customized education.</ItalicText>
            <YouTubeLogo src="/youtube.png" alt="YouTube Logo" />
          </MentionLink>
        </ListItem>
      </MentionsList>
    </Container>
  );
};

export default MentionsPage;
