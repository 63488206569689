import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

// Styled components
const AboutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '"Poppins", sans-serif',
  minHeight: '100vh',
  width: '100%',
  '@media (min-width: 769px)': {
    flexDirection: 'column',
  },
});

const Header = styled('div')({
  width: '100%',
  textAlign: 'center',
  padding: '10px 0', // Less padding vertically
  backgroundColor: '#fff',
  borderBottom: '1px solid #ddd',
});

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '@media (min-width: 769px)': {
    flexDirection: 'row',
    width: '100%',
  },
});

const Section = styled('div')({
  flex: '1',
  padding: '20px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media (min-width: 769px)': {
    padding: '40px 20px',
  },
});

const LeftSection = styled(Section)({
  backgroundColor: '#fff',
});

const RightSection = styled(Section)({
  backgroundColor: '#000',
  color: '#fff',
});

const SectionTitle = styled('h2')({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '20px',
});

const SectionText = styled('p')({
  lineHeight: '1.6',
});

const Title = styled('h1')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin: '0 0 10px 0',
  padding: 0,
  lineHeight: '1',
  fontFamily: '"Saira Condensed", sans-serif',
  paddingLeft: '10px', // Adjust padding on the left of the title
  paddingTop: '0px', // Remove top padding
  paddingBottom: '0px', // Remove bottom padding
  '@media (max-width: 768px)': {
    fontSize: '2em',
    paddingLeft: '0px',
    textAlign: 'center',
  },
});

const ProfileImage = styled('img')({
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  marginRight: '10px', // More margin to the right of the icon
  '@media (max-width: 768px)': {
    margin: '0 auto 10px',
    display: 'block',
  },
});

const NameText = styled('div')({
  fontFamily: '"Indie Flower", cursive',
  fontSize: '1.5em',
  paddingLeft: '8px',
  marginRight: '10px', // Less margin to bring the title closer to the name
  '@media (max-width: 768px)': {
    textAlign: 'center',
    marginTop: '10px',
  },
});

const ProfileAndTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center align the items horizontally
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    textAlign: 'center',
  },
});

const ImageWithPosition = styled('img')({
  display: 'block',
  maxWidth: '100%',
  margin: '20px auto',
  objectFit: 'contain',
  '@media (max-width: 768px)': {
    position: 'relative',
    margin: '20px auto',
  },
});

const ImageWithPositionFaba = styled('img')({
  display: 'block',
  maxWidth: '75%', // Adjust the percentage to make the image smaller
  margin: '20px auto',
  objectFit: 'contain',
  '@media (max-width: 768px)': {
    position: 'relative',
    maxWidth: '75%', // Adjust for smaller screens
    margin: '10px auto',
  },
});

const RoleSection = () => {
  return (
    <AboutContainer>
      <Header>
        <ProfileAndTitleContainer>
          <Link to="/">
            <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
          </Link>
          <NameText>Andy Spezzatti</NameText>
          <Title>/ Roles.</Title>
        </ProfileAndTitleContainer>
      </Header>
      <ContentContainer>
        <RightSection>
          <SectionTitle>Chief Technology Officer.</SectionTitle>
          <SectionText>
            I am currently the CTO at Taltrics.
            Taltrics is an AI-driven resource management platform.
            It equips leaders to optimally allocate the right employee and non-human capital to the right work at the right time in complex portfolios,
            using a user-friendly AI-driven platform that learns from past allocation outcomes. It helps reduce scheduling conflicts,
            unbalanced capacity across geographies, underutilization of skilled personnel, and budget overruns.
            It pulls data from ERP/PPM and replaces spreadsheet-based models.
            Our matching recommendation models get improved over time using a human in the loop system.
            On the platform, we monitor user actions, such as preferences in our recommendation rankings,
            to refine our models through RLHF (Reinforcement Learning with Human Feedback) loops.
            This approach uncovers latent patterns and insights,
            like re-prioritization trends or information typically confined to emails and calls, and learns from real-time allocation outcomes.
          </SectionText>
        </RightSection>
        <RightSection>
          <ImageWithPositionFaba src="/faba.png" alt="FABA" />
        </RightSection>
      </ContentContainer>
      <ContentContainer>
        <LeftSection>
          <SectionTitle>Sr. Portfolio Data Science Lead.</SectionTitle>
          <SectionText>
            I supported the Global Clinical Operation group at Genentech / Roche for 4 years, delivering cross-portfolio optimization insights.
            I supported a portfolio of 400+ studies across 70+ countries and thousands of sites.
            I led resourcing and strategic initiatives to enroll the right patients at the right time and supported the operational strategy and execution of clinical trials across the globe.
            One of my key projects has been to develop analytics models to enable a smarter strategic selection of countries. These models leverage data on local epidemiology,
            standard of care, infrastructure availability, operational efficiency, and talent capacity. This led to over 30% cost savings and improved operational efficiency.
            I also enabled better planning through predictive Line of Sight and Scenario Planning capabilities to simulate future business needs and related resourcing constraints.
            I partnered closely with operational country heads, the finance group, and the portfolio planning and strategy group.
          </SectionText>
        </LeftSection>
        <LeftSection>
          <ImageWithPositionFaba src="/icons/genentech.jpg" alt="Genentech" />
        </LeftSection>
      </ContentContainer>
      <ContentContainer>
        <RightSection>
          <SectionTitle>AI Project Leader.</SectionTitle>
          <SectionText>
            At AI4Good, I have led three major AI projects and established partnerships with prominent organizations such as Microsoft, AWS, Ekipa, and The Bright Data Initiative.
            I have directed teams of numerous AI researchers in developing natural language processing models aimed at addressing important challenges,
            including providing easy access to critical data related to the Sustainable Development Goals (SDGs).
            Additionally, I have coordinated multidisciplinary teams comprising economists, computer scientists, social scientists, researchers, designers,
            and engineers to ensure successful project execution.
            My contributions also include presenting our projects at various AI conferences across the US and publishing our research in peer-reviewed academic journals.
          </SectionText>
        </RightSection>
        <RightSection>
          <ImageWithPositionFaba src="/kdd19.png" alt="KDD 2019" />
        </RightSection>
      </ContentContainer>
      <ContentContainer>
        <LeftSection>
          <SectionTitle>Responsible AI Researcher.</SectionTitle>
          <SectionText>
            My research has been focused on Ethical AI within the framework of the Z-inspection initiative.
            As part of this initiative, I explored four distinct clinical use cases where AI is applied in medical settings.
            The primary objective of this research was to scrutinize the ethical considerations surrounding the deployment of AI technologies.
            I led the technical side of this investigation, which involved assessing techniques in Explainable AI,
            enhancing interpretability, addressing model biases, and ensuring robust data privacy measures.
            Throughout this initiative, we have adopted a multidisciplinary approach, engaging with clinicians, ethicists, and data scientists to ensure a holistic assessment of AI applications in healthcare. My responsibilities included the development and implementation of frameworks for assessing AI transparency and fairness.
            We conducted several workshops and training sessions to educate stakeholders on ethical AI principles and practices.
            This work not only highlighted the potential risks and mitigation strategies for AI in healthcare but also underscored the importance of continuous ethical evaluation as these technologies evolve.
          </SectionText>
        </LeftSection>
        <LeftSection>
          <ImageWithPositionFaba src="/archi.png" alt="Archi" />
        </LeftSection>
      </ContentContainer>
    </AboutContainer>
  );
};

export default RoleSection;
