import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MusicTabs from './mainPage'; // Assume this is your component with the sidebar and tabs
import MentionsPage from "./mentionPage"
import PapersPage from "./paperPage"
import ContactPage from './contactPage';
import AboutSection from './aboutPage';
import SocialsSection from './socialPage';
import RoleSection from './rolePage';
import SuggestionsPage from "./suggestPage"
import ProjectSection from './projectPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MusicTabs />} />
        <Route path="/mention" element={<MentionsPage />} />
        <Route path="/paper" element={<PapersPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutSection />} />
        <Route path="/social" element={<SocialsSection />} />
        <Route path="/role" element={<RoleSection />} />
        <Route path="/suggest" element={<SuggestionsPage />} />
        <Route path="/project" element={<ProjectSection />} />
      </Routes>
    </Router>
  );
};

export default App;
