import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const theme = createTheme();

// Replace these paths with the actual paths to your SVG or image assets
const socialMediaIcons = {
  linkedin: '/icons/linkedin.webp',
  googleScholar: '/icons/scholar.png',
  github: '/icons/github.png',
  medium: '/icons/medium.png',
  xDotCom: '/icons/x.webp',
};


const SocialsContainer = styled('div')(({ theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 16px',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '20px',
  },
}));

const Row = styled('div')(({ theme: THeme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const SocialBox = styled(Link)(({ theme }) => ({
  width: '100%',
  maxWidth: '300px',
  borderRadius: '20px',
  border: '1px solid #ddd',
  padding: '20px',
  margin: '10px 0',
  textAlign: 'center',
  textDecoration: 'none',
  color: '#333',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
    cursor: 'pointer',
  },
  '& img': {
    width: '50px',
    height: '50px',
    margin: '0 auto',
  },
  [theme.breakpoints.up('md')]: {
    width: '180px',
    margin: '10px',
  },
}));

const SocialTitle = styled('h3')({
  margin: '20px 0 10px',
});

const SocialDescription = styled('p')({
  fontSize: '0.9rem',
  fontStyle: 'italic', // Apply italic styling
  textDecoration: 'none', // Ensure no underline
});

const socialsData = [
  {
    name: 'LinkedIn',
    icon: socialMediaIcons.linkedin,
    description: 'Follow me on LinkedIn for professional updates and networking opportunities.',
    url: 'https://www.linkedin.com/in/andy-spezzatti-32248612b/',
  },
  {
    name: 'Google Scholar',
    icon: socialMediaIcons.googleScholar,
    description: 'View my academic publications and citations on Google Scholar.',
    url: 'https://scholar.google.com/citations?user=59r5sJ0AAAAJ&hl=fr&oi=ao',
  },
  {
    name: 'GitHub',
    icon: socialMediaIcons.github,
    description: 'View my projects and code repositories on GitHub.',
    url: 'https://github.com/AndySECP',
  },
  {
    name: 'Medium',
    icon: socialMediaIcons.medium,
    description: 'View my blog posts where I share thoughts and ideas on Medium.',
    url: 'https://medium.com/@andy_spezzatti',
  },
  {
    name: 'X',
    icon: socialMediaIcons.xDotCom,
    description: 'View my updates and thoughts on X.',
    url: 'https://twitter.com/ASpezzatti',
  },
];

const Title = styled('h1')({
  fontSize: '3em', // Increased from '2em' to '3em' for a larger size
  fontWeight: 'bold',
  margin: '0 0 20px 0', // Adds bottom margin to separate multiple titles
  padding: 0,
  lineHeight: '1', // Adjust line height for better readability at larger sizes
  fontFamily: '"Saira Condensed", sans-serif',
  paddingLeft: '250px',
  paddingTop: '16px',
  paddingBottom: '32px',
  '@media (max-width: 768px)': {
    fontSize: '2em',
    paddingLeft: '0',
    textAlign: 'center',
  },
});

const ProfileImage = styled('img')({
  position: 'absolute', // Keeps the image positioned relative to the nearest positioned ancestor (Sidebar)
  top: '20px', // Distance from the top of the Sidebar
  left: '20px', // Distance from the left of the Sidebar
  borderRadius: '50%', // Makes the image round
  width: '50px', // Reduces the width to 50px
  height: '50px', // Reduces the height to 50px
});

const NameText = styled('div')({
  position: 'absolute', // Absolute positioning to place it relative to the sidebar
  top: '30px', // Adjusted to align with the middle of the image
  left: '75px', // Adjusted to place it right next to the image
  fontFamily: '"Indie Flower", cursive', // Setting the font family to Indie Flower
  fontSize: '1.5em', // Appropriately sized text
  paddingLeft: '8px',
  '@media (max-width: 768px)': {
    position: 'static',
    textAlign: 'center',
    marginTop: '10px',
  },
});

const ProfileAndTitleContainer = styled('div')({
  position: 'absolute', // Position relative to the sidebar
  top: '0px',
  left: '0px',
  zIndex: 10,
  '@media (max-width: 768px)': {
    position: 'static',
    textAlign: 'center',
  },
});

const MobileSliderContainer = styled('div')({
  width: '100%',
  padding: '20px 0',
  position: 'relative',
});

const SliderNavigation = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '20px',
});

const SocialsSection = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (event: Event, newValue: number | number[]) => {
    setCurrentSlide(newValue as number);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev > 0 ? prev - 1 : socialsData.length - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev < socialsData.length - 1 ? prev + 1 : 0));
  };

  return (
    <ThemeProvider theme={theme}>
      <SocialsContainer>
        <ProfileAndTitleContainer>
          <Link to="/">
            <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
          </Link>
          <NameText>Andy Spezzatti</NameText>
          <Title>/ Socials.</Title>
        </ProfileAndTitleContainer>

        {isMobile ? (
          <MobileSliderContainer>
            <SocialBox to={socialsData[currentSlide].url} target="_blank" rel="noopener noreferrer">
              <img src={socialsData[currentSlide].icon} alt={`${socialsData[currentSlide].name} icon`} />
              <SocialTitle>{socialsData[currentSlide].name}</SocialTitle>
              <SocialDescription>{socialsData[currentSlide].description}</SocialDescription>
            </SocialBox>
            <SliderNavigation>
              <IconButton onClick={handlePrev}>
                <ArrowBackIosNewIcon />
              </IconButton>
              <Slider
                value={currentSlide}
                onChange={handleSlideChange}
                min={0}
                max={socialsData.length - 1}
                step={1}
                marks
                sx={{ width: '60%' }}
              />
              <IconButton onClick={handleNext}>
                <ArrowForwardIosIcon />
              </IconButton>
            </SliderNavigation>
          </MobileSliderContainer>
        ) : (
          <>
            <Row sx={{ paddingTop: "180px" }}>
              {socialsData.slice(0, 3).map((social) => (
                <SocialBox key={social.name} to={social.url} target="_blank" rel="noopener noreferrer">
                  <img src={social.icon} alt={`${social.name} icon`} />
                  <SocialTitle>{social.name}</SocialTitle>
                  <SocialDescription>{social.description}</SocialDescription>
                </SocialBox>
              ))}
            </Row>
            <Row>
              {socialsData.slice(3, 5).map((social) => (
                <SocialBox key={social.name} to={social.url} target="_blank" rel="noopener noreferrer">
                  <img src={social.icon} alt={`${social.name} icon`} />
                  <SocialTitle>{social.name}</SocialTitle>
                  <SocialDescription>{social.description}</SocialDescription>
                </SocialBox>
              ))}
            </Row>
          </>
        )}
      </SocialsContainer>
    </ThemeProvider>
  );
};

export default SocialsSection;