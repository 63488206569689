import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const Container = styled('div')({
  padding: '20px',
  '@media (max-width: 600px)': {
    padding: '10px',
  },
});

const MentionsList = styled('ul')({
  listStyleType: 'none',
  position: 'relative',
  paddingLeft: '0',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '150px',
    top: 0,
    bottom: 0,
    width: '2px',
    backgroundColor: 'black',
    '@media (max-width: 600px)': {
      left: '10%',
    },
  },
});

const ListItem = styled('li')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  position: 'relative',
  paddingLeft: '0',
  '@media (max-width: 600px)': {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::before': {
      display: 'none', // Hide the timeline dot on small screens
    },
  },
});

const DateText = styled('span')({
  fontFamily: '"Indie Flower", cursive',
  color: 'black',
  width: '130px',
  textAlign: 'right',
  position: 'absolute',
  left: '10px',
  fontSize: '1em',
  '@media (max-width: 600px)': {
    width: 'auto',
    left: '0',
    fontSize: '0.9em',
    position: 'relative', // Use relative positioning for small screens
    order: 1, // Ensure DateText is ordered before MentionLink
    textAlign: 'left',
    marginLeft: '10px', // Add some spacing between icon and date
    whiteSpace: 'nowrap',
  },
});

const MentionLink = styled('a')({
  display: 'block',
  color: 'black',
  textDecoration: 'none',
  fontFamily: '"Poppins", sans-serif',
  padding: '10px',
  marginLeft: '190px',
  flexGrow: 1,
  '&:hover': {
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  },
  '& strong': {
    fontWeight: 'bold',
  },
  '& em': {
    fontStyle: 'italic',
  },
  '@media (max-width: 600px)': {
    marginLeft: '10px',
    order: 3, // Ensure MentionLink is ordered last
  },
});

const Icon = styled('img')({
  position: 'absolute',
  left: '170px',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '24px',
  height: '24px',
  '@media (max-width: 600px)': {
    left: '0',
    position: 'relative', // Use relative positioning for small screens
    order: 2, // Ensure Icon is ordered after DateText
    marginLeft: '10px',
  },
});

const Title = styled('h1')({
  fontSize: '3em',
  fontWeight: 'bold',
  margin: '0 0 20px 0',
  padding: 0,
  lineHeight: '1',
  fontFamily: '"Saira Condensed", sans-serif',
  paddingLeft: '250px',
  paddingTop: '16px',
  paddingBottom: '32px',
  '@media (max-width: 600px)': {
    fontSize: '2em',
    paddingLeft: '0',
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '20px',
  },
});

const ProfileImage = styled('img')({
  position: 'absolute',
  top: '20px',
  left: '20px',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  '@media (max-width: 600px)': {
    position: 'relative',
    top: '0',
    left: '0',
    margin: '0 auto',
    display: 'block',
  },
});

const NameText = styled('div')({
  position: 'absolute',
  top: '30px',
  left: '75px',
  fontFamily: '"Indie Flower", cursive',
  fontSize: '1.5em',
  paddingLeft: '8px',
  '@media (max-width: 600px)': {
    position: 'relative',
    top: '0',
    left: '0',
    textAlign: 'center',
    marginTop: '10px',
  },
});

const MentionsPage = () => {
  return (
    <Container>
      <Link to="/">
        <ProfileImage src="/rounded_personal_picts.jpg" alt="Profile Picture" />
      </Link>
      <NameText>Andy Spezzatti</NameText>
      <Title>/ Blog & Mentions.</Title>
      <MentionsList>
        <ListItem>
          <Icon src="/socially_conscious_ai.jpg" alt="Medium Icon" />
          <DateText>
            Sep <br /> 2024
          </DateText>
          <MentionLink href="https://www.youtube.com/watch?v=wk0BcTN8420&list=PLjCgOlNmdgOTx_LxgCFXcSFk-9SgRxfoQ&index=2" target="_blank"><strong>Socially Conscious AI Podcast</strong> &#8212; <em>AI and the Future of Global Sustainability w/ Andy Spezzatti, CTO of Taltrics</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/tds.png" alt="Medium Icon" />
          <DateText>
            Apr <br /> 2024
          </DateText>
          <MentionLink href="https://towardsdatascience.com/the-quest-for-clarity-are-interpretable-neural-networks-the-future-of-ethical-ai-aea40745b95a" target="_blank"><strong>Towards Data Science</strong> &#8212; <em>The Quest for Clarity: Are Interpretable Neural Networks the Future of Ethical AI?</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/towardsai.png" alt="Medium Icon" />
          <DateText>
            Apr <br /> 2024
          </DateText>
          <MentionLink href="https://pub.towardsai.net/are-llms-only-good-for-chat-based-solutions-exploring-beyond-language-tasks-5bcbe2b41bae" target="_blank"><strong>Towards AI</strong> &#8212; <em>Are LLMs Only Good for Chat-Based Solutions? Exploring Beyond Language Tasks</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/taltrics_logo.png" alt="Medium Icon" />
          <DateText>
            Oct <br /> 2023
          </DateText>
          <MentionLink href="https://medium.com/taltrics/to-build-or-to-buy-a-deep-dive-into-the-strategic-dilemma-of-software-adoption-c0239c62e254" target="_blank"><strong>Taltrics</strong> &#8212; <em>To Build or to Buy? A Deep Dive into the Strategic Dilemma of Software Adoption</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/taltrics_logo.png" alt="Medium Icon" />
          <DateText>
            Jun <br /> 2023
          </DateText>
          <MentionLink href="https://medium.com/taltrics/scenario-planning-a-lighthouse-in-the-fog-of-uncertainty-34fe6224adb5" target="_blank"><strong>Taltrics</strong> &#8212; <em>Scenario Planning: A Lighthouse in the Fog of Uncertainty</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/taltrics_logo.png" alt="Medium Icon" />
          <DateText>
            Nov <br /> 2022
          </DateText>
          <MentionLink href="https://medium.com/taltrics/12-tips-on-modern-recruitment-workforce-planning-practices-5402df02cd48" target="_blank"><strong>Taltrics</strong> &#8212; <em>12 Tips on Modern Recruitment Workforce Planning Practices</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/tds.png" alt="Medium Icon" />
          <DateText>
            Jan <br /> 2021
          </DateText>
          <MentionLink href="https://towardsdatascience.com/when-ai-gets-it-wrong-2d561e7d7d73" target="_blank"><strong>Towards Data Science</strong> &#8212; <em>When AI Gets it Wrong</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/tds.png" alt="Medium Icon" />
          <DateText>
            Nov <br /> 2020
          </DateText>
          <MentionLink href="https://towardsdatascience.com/when-data-science-fails-us-78599de6423c" target="_blank"><strong>Towards Data Science</strong> &#8212; <em>When Data Science Fails us</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/tds.png" alt="Medium Icon" />
          <DateText>
            Jun <br /> 2020
          </DateText>
          <MentionLink href="https://towardsdatascience.com/time-for-sustainable-development-26ba969f5b46" target="_blank"><strong>Towards Data Science</strong> &#8212; <em>Time for Sustainable Development</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/tds.png" alt="Medium Icon" />
          <DateText>
            Aug <br /> 2018
          </DateText>
          <MentionLink href="https://towardsdatascience.com/neural-networks-for-music-generation-97c983b50204" target="_blank"><strong>Towards Data Science</strong> &#8212; <em>Neural Networks for Music Generation</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/funginstitute.png" alt="Berkeley Fung Institute Icon" />
          <DateText>
            Jun <br /> 2019
          </DateText>
          <MentionLink href="https://funginstitute.medium.com/meng-alumnus-andy-spezzatti-speaks-at-sigkdd-2019-380fe44b022b" target="_blank"><strong>Berkeley Fung Institute for Engineering Leadership</strong> &#8212; <em>MEng alumnus Andy Spezzatti speaks at SIGKDD 2019</em></MentionLink>
        </ListItem>
        <ListItem>
          <Icon src="/tds.png" alt="Medium Icon" />
          <DateText>
            Jun <br /> 2019
          </DateText>
          <MentionLink href="https://towardsdatascience.com/human-centered-education-85f9a9702076" target="_blank"><strong>Towards Data Science</strong> &#8212; <em>Towards Human Centered Education</em></MentionLink>
        </ListItem>
      </MentionsList>
    </Container>
  );
};

export default MentionsPage;
